import logo from './logo.svg';
import './App.css';

import numerals from './assets/numeralsPath';
import majorsPath from './assets/majorsPath';
import minorsDim from './assets/minorsDim';
import notesPath from './assets/notesPath';
//https://codepen.io/2kool2/pen/bmVxpZ

function App() {
  return (<>
        
      
<div data-fullScreen className="circle">


  <div className="circle_inner" data-drag draggable="true">

<svg className="svg_circle-of-fifths" viewBox="0 0 960 960" draggable="false">

  

  <circle className="bgLayer" cx="480" cy="480" r="180" strokeWidth="4"/>


  <g className="clickLayer">

    <g className="clickLayer_minor">
      <path data-rotate="0" data-duration="0.3" d="M512 216l11-88c31 4 61 12 90 23l-33 83-68-18z"/>
      <path data-rotate="-60" data-duration="0.4" d="M640 267l52-70c25 19 48 41 67 66l-69 53-50-49z"/>
      <path data-rotate="-60" data-duration="0.5" d="M725 377l81-35c13 29 21 59 25 90l-88 12-18-67z"/>
      <path data-rotate="-120" data-duration="0.6" d="M743 513l88 11c-3 31-11 61-23 89l-82-32 17-68z"/>
      <path data-rotate="-120" data-duration="0.7" d="M692 640l71 53c-19 25-41 47-65 67l-55-70 49-50z"/>
      <path data-rotate="-180" data-duration="0.8" d="M584 724l35 82c-28 12-58 21-89 25l-13-88 67-19z"/>
      <path data-rotate="-180" data-duration="0.8" d="M381 726c21 9 68 18 68 18l-11 88c-31-4-61-12-90-23l33-83z"/>
      <path data-rotate="120" data-duration="0.7" d="M271 644c15 19 50 49 50 49l-53 71c-25-19-47-41-66-65l69-55z"/>
      <path data-rotate="120" data-duration="0.6" d="M217 518l19 67-82 35c-12-29-20-59-24-89l87-13z"/>
      <path data-rotate="60" data-duration="0.5" d="M234 381l-18 67-88-10c4-31 12-62 23-90l83 33z"/>
      <path data-rotate="60" data-duration="0.4" d="M317 271l-49 50-71-54c18-25 40-47 65-66l55 70z"/>
      <path data-rotate="0" data-duration="0.3" d="M444 217l-67 19-35-82c28-12 58-21 89-25l13 88z"/>
    </g>

    <g className="clickLayer_major">
      <path data-rotate="0" data-duration="0" d="M446 127l-10-92c21-3 57-2 79-1l-7 93 15 2-11 86 36 7-23 84c-31-9-59-8-93 0l-22-84 33-6-13-86 16-3z"/>
      <path data-rotate="-30" data-duration="0.3" d="M612 152l15 6 38-85c24 11 46 24 68 39l-53 76 12 9-52 69c10 7 18 16 27 24l-61 61c-23-21-51-38-81-45l23-84 32 10 32-80z"/>
      <path data-rotate="-60" data-duration="0.4" d="M769 274l75-54c15 21 29 45 40 68l-84 40 6 14-80 34c5 11 9 23 12 35l-84 23c-9-31-26-61-48-82l61-62 24 26 68-55 10 13z"/>
      <path data-rotate="-90" data-duration="0.5" d="M654 433l84-22 7 32 86-14 2 16 93-8c3 25 2 53 0 79l-93-7-2 14-86-10-7 36-84-23c8-30 9-57 0-93z"/>
      <path data-rotate="-120" data-duration="0.6" d="M654 526l84 23c-3 11-6 22-11 32l81 32-6 14 86 38c-11 23-24 46-39 68l-77-52-10 12-69-52c-7 10-15 19-24 28l-61-62c22-22 38-51 46-81z"/>
      <path data-rotate="-150" data-duration="0.7" d="M608 607l61 62-25 22 54 68-11 9 54 76c-21 15-45 30-69 40l-40-84-14 6-34-80c-11 5-22 9-34 12l-23-84c31-9 59-25 81-47z"/>
      <path data-rotate="-180" data-duration="0.8" d="M452 833l-15-1 11-87c-12-1-24-4-36-7l22-84c34 8 62 8 93 0l23 84-33 6 13 86-16 3 9 93a524 524 0 0 1-78 1l7-94z"/>
      <path data-rotate="150" data-duration="0.7" d="M354 608c22 22 50 38 80 46l-22 84c-11-2-22-6-32-10l-32 81-15-7-38 87c-23-11-47-26-69-40l54-77-12-9 52-70-28-23 62-62z"/>
      <path data-rotate="120" data-duration="0.6" d="M161 633l-7-14 81-34c-5-11-9-23-12-35l83-22c9 30 26 58 48 80l-62 62-22-25-68 53-10-12-76 54c-15-21-29-44-40-67l85-40z"/>
      <path data-rotate="90" data-duration="0.5" d="M127 453l1-15 87 10 7-35 84 22c-8 30-8 63 0 93l-84 22c-3-10-5-21-6-32l-86 12-2-16-93 10c-2-25-2-52 0-78l92 7z"/>
      <path data-rotate="60" data-duration="0.4" d="M188 280l9-12 69 52c7-10 16-19 25-27l61 60c-22 22-38 52-46 82l-84-23c2-11 6-21 10-31l-81-32 7-16-84-39c11-23 23-46 38-67l76 53z"/>
      <path data-rotate="30" data-duration="0.3" d="M327 160l14-6 34 80c11-5 23-8 35-12l23 84c-30 8-59 25-81 47l-61-61 24-22-54-68 13-10-54-75c21-15 43-27 66-39l41 82z"/>
    </g>

  </g>



  <g className="rotationLayer">

    <path className="rotationLayer_bg" d="M480 33a447 447 0 1 1 0 894 447 447 0 0 1 0-894zm0 93a354 354 0 1 1 0 708 354 354 0 0 1 0-708z"/>

    <g className="rotationLayer_minor">
      <path fill="#f2ffcc" d="M512 216l11-88c31 4 61 12 90 23l-33 83-68-18z"/>
      <path fill="#d9ffcc" d="M640 267l52-70c25 19 48 41 67 66l-69 53-50-49z"/>
      <path fill="#ddeee1" d="M725 377l81-35c13 29 21 59 25 90l-88 12-18-67z"/>
      <path fill="#ddfff7" d="M743 513l88 11c-3 31-11 61-23 89l-82-32 17-68z"/>
      <path fill="#ccf2ff" d="M692 640l71 53c-19 25-41 47-65 67l-55-70 49-50z"/>
      <path fill="#ccd9ff" d="M584 724l35 82c-28 12-58 21-89 25l-13-88 67-19z"/>
      
  
      <path fill="#d9ccff" d="M381 726c21 9 68 18 68 18l-11 88c-31-4-61-12-90-23l33-83z"/>
      
      <path fill="#efccfa" d="M271 644c15 19 50 49 50 49l-53 71c-25-19-47-41-66-65l69-55z"/>
      <path fill="#f6cdec" d="M217 518l19 67-82 35c-12-29-20-59-24-89l87-13z"/>
      <path fill="#ffc6d4" d="M234 381l-18 67-88-10c4-31 12-62 23-90l83 33z"/>
      <path fill="#fcb" d="M317 271l-49 50-71-54c18-25 40-47 65-66l55 70z"/>
      <path fill="#fff5d8" d="M444 217l-67 19-35-82c28-12 58-21 89-25l13 88z"/>
    </g>

    <g className="rotationLayer_major">
      <path fill="#ffc" d="M446 127l-10-92c21-3 57-2 79-1l-7 93 15 2-11 86 36 7-23 84c-31-9-59-8-93 0l-22-84 33-6-13-86 16-3z"/>
      <path fill="#e6ffcc" d="M612 152l15 6 38-85c24 11 46 24 68 39l-53 76 12 9-52 69c10 7 18 16 27 24l-61 61c-23-21-51-38-81-45l23-84 32 10 32-80z"/>
      <path fill="#cfc" d="M769 274l75-54c15 21 29 45 40 68l-84 40 6 14-80 34c5 11 9 23 12 35l-84 23c-9-31-26-61-48-82l61-62 24 26 68-55 10 13z"/>
      <path fill="#ccffe6" d="M654 433l84-22 7 32 86-14 2 16 93-8c3 25 2 53 0 79l-93-7-2 14-86-10-7 36-84-23c8-30 9-57 0-93z"/>
      <path fill="#cff" d="M654 526l84 23c-3 11-6 22-11 32l81 32-6 14 86 38c-11 23-24 46-39 68l-77-52-10 12-69-52c-7 10-15 19-24 28l-61-62c22-22 38-51 46-81z"/>
      <path fill="#cce6ff" d="M608 607l61 62-25 22 54 68-11 9 54 76c-21 15-45 30-69 40l-40-84-14 6-34-80c-11 5-22 9-34 12l-23-84c31-9 59-25 81-47z"/>
      <path fill="#ccf" d="M452 833l-15-1 11-87c-12-1-24-4-36-7l22-84c34 8 62 8 93 0l23 84-33 6 13 86-16 3 9 93a524 524 0 0 1-78 1l7-94z"/>
      <path fill="#e5cbff" d="M354 608c22 22 50 38 80 46l-22 84c-11-2-22-6-32-10l-32 81-15-7-38 87c-23-11-47-26-69-40l54-77-12-9 52-70-28-23 62-62z"/>
      <path fill="#fcf" d="M161 633l-7-14 81-34c-5-11-9-23-12-35l83-22c9 30 26 58 48 80l-62 62-22-25-68 53-10-12-76 54c-15-21-29-44-40-67l85-40z"/>
      <path fill="#ffcce6" d="M127 453l1-15 87 10 7-35 84 22c-8 30-8 63 0 93l-84 22c-3-10-5-21-6-32l-86 12-2-16-93 10c-2-25-2-52 0-78l92 7z"/>
      <path fill="#fcc" d="M188 280l9-12 69 52c7-10 16-19 25-27l61 60c-22 22-38 52-46 82l-84-23c2-11 6-21 10-31l-81-32 7-16-84-39c11-23 23-46 38-67l76 53z"/>
      <path fill="#ffe6cc" d="M327 160l14-6 34 80c11-5 23-8 35-12l23 84c-30 8-59 25-81 47l-61-61 24-22-54-68 13-10-54-75c21-15 43-27 66-39l41 82z"/>
    </g>

    <g className="textLayer chords-as-curves" fill="currentcolor">
      
      ${notesPath}
      
      </g>
  </g>


  <g className="circlesLayer">
    <circle cx="480" cy="480" r="180" strokeWidth="2"/>
    <circle cx="480" cy="480" r="267"/>
    <circle cx="480" cy="480" r="354"/>
    <circle cx="480" cy="480" r="447" strokeWidth="2"/>
  </g>


  <g className="shieldLayer">
    <path className="sheildLayer_overlay" d="M654 432l84-21c-30-92-81-146-158-179l32-81c-28-12-74-22-104-24l7-93a447 447 0 1 1-79 1l10 93c-35 4-76 14-105 26l34 80c-36 15-68 40-85 58l61 60c-33 34-51 80-51 128 0 99 78 181 177 180 60 0 99-20 130-52l60 60c27-23 61-85 69-120l-82-23c8-30 8-63 0-93z"/>
    <path className="sheildLayer_sus4" d="M606 351l61-61c36 36 59 78 70 120l-82 22c-9-32-26-59-49-81zM654 525l83 23c-8 35-42 96-69 119l-60-60c22-21 38-51 46-82z"/>
    <path className="shieldLayer_key" d="M433 337v-31l47-14 45 14v31h-92z"/>
  </g>



  <g className="shieldTextLines">
    <text className="shieldTextLines_circle" x="425" y="448" fontSize="20">Circle of Fifths</text>
    <text className="shieldTextLines_click" x="395" y="484" fontSize="14">Click any chord to change key.</text>
    <text className="shieldTextLines_tap requiresPointerEvents" x="390" y="510" fontSize="14">Tap &amp; drag left &harr; right to rotate.</text>
    <text className="shieldTextLines_key" x="460" y="322" fontSize="24">Key</text>
  </g>


  <g className="shieldText text-as-curves">
    ${numerals}
    ${majorsPath}
    ${minorsDim}
    
  </g>

</svg>



  </div>
</div>


    <svg style={{display:"none"}}>
      {/*  Icons adapted from those made by https://www.flaticon.com/authors/freepik off https://www.flaticon.com/ */}
      <symbol id="icon-fullScreen-open" viewBox="0 0 96 96">
        <path d="M0 62l12 12 19-19 10 10-19 19 12 12H0V62zm96 34H62l12-12-19-19 10-10 19 19 12-12v34zM34 0L22 12l19 19-10 10-19-19L0 34V0h34zm62 0v34L84 22 65 41 55 31l19-19L62 0h34H0h96z"/>
      </symbol>
      <symbol id="icon-fullScreen-exit" viewBox="0 0 96 96">
        <path d="M96 60L82 74l14 13-8 9-14-14-14 14V60h36zm-60 0v36L22 82 8 96l-8-9 14-13L0 60h36zM0 36h36V0L22 14 9 0 0 9l14 13L0 36zm60 0h36L82 22 96 9l-9-9-13 14L60 0v36z"/>
      </symbol>
    </svg>



        
      </>
  );
}



var supportsES6 = function() {
  // https://gist.github.com/bendc/d7f3dbc83d0f65ca0433caf90378cd95
  try {
    new Function("(a = 0) => a");
    return true;
  }
  catch (err) {
    return false;
  }
}();


var supportsPointerEvents = function() {
  if (window.PointerEvent) {
    document.documentElement.className += " hasPointerEvents"
  } else {
    document.documentElement.className += " noPointerEvents"
  }
  return (window.PointerEvent);
}




// Click any chord to rotate the chord wheel.
// Or drag the wheel left, or right, by 32px or more.
// Drag uses pointer events, so unavailable in Safari.
// Version 5 01/10/2019

(function (window, document) {

  // 12 semitones across 360deg.
  // 1 semitone rotation is a 30deg step 

  "use strict";
  if (!supportsES6) return false;

  const config = {
    clickSelect : '.clickLayer',
    dragLayerSelect : '[data-drag]',
    rotationSelect : '.rotationLayer',
    rotationVar : '--rotation',
    rotationAttr : 'data-rotate',
    durationVar : '--transitionDuration',
    durationAttr : 'data-duration',
    durationDefault : '0.3',
    minDrag : 32
  };
  
  // Get the required control and data elements.
  // Exit if unavailable.

  const clickLayer = document.querySelector(config.clickSelect);
  if (!clickLayer) return false;

  const paths = clickLayer.querySelectorAll(`[${config.rotationAttr}]`);
  if (!paths) return false;

  const dragLayer = document.querySelector(config.dragLayerSelect);
  if (!dragLayer) return false;

  const rotationLayer = document.querySelector(config.rotationSelect);
  if (!rotationLayer) return false;

  const positionX = {
    start : 0,
    end : 0
  };

  const _setCssVars = (duration, rotation) => {
    
    // V3a - Also rotates a mirrored version
    const rotationLayers = document.querySelectorAll(config.rotationSelect);
    if (!rotationLayers) return false;
    for (const rotationLayer of rotationLayers) {
      window.requestAnimationFrame(_ => {
        rotationLayer.style.setProperty(config.durationVar, duration + 's');
        rotationLayer.style.setProperty(config.rotationVar, rotation + 'deg');
      });
    }
  };

  const _getCurrentRotation = _ => {
    const currentRotation = getComputedStyle(rotationLayer)
        .getPropertyValue(config.rotationVar)
        .replace('deg', '');
    return parseInt(currentRotation || 0);
  };

  const _getClickedRotateTo = (element, current) => {
    const rotate = element.getAttribute(config.rotationAttr);
    return current - (rotate || 0) * -1;
  };

  const _getDuration = element => {
    const duration = element.getAttribute(config.durationAttr);
    return duration || config.durationDefault;
  };
  
  const _resetRotation = _ => {
    // Keeps rotation value within +/-360 degrees
    const current = _getCurrentRotation();
    if (current >= -360 && current <= 360) return;

    const rotation = Math.floor(current % 360);
    _setCssVars(0, rotation);
  }

  const _chordClicked = event => {
    const current = _getCurrentRotation();
    const rotation = _getClickedRotateTo(event.target, current);
    const duration = _getDuration(event.target);
    _setCssVars(duration, rotation);
  };


  // Dragging the wheel left or right using pointer events (sorry Safari)

  const _hasDraggedEnough = _ => {
    // Has the cursor travelled far enough?
    const isEndMore = positionX.end > (positionX.start + config.minDrag);
    const isEndLess = positionX.end < (positionX.start - config.minDrag);
    return isEndMore || isEndLess;
  };

  const _getDraggedRotateTo = current => {
    // 30 (deg) is a twelth of 360 degrees = 1 chord (semitone)
    const rotateBy = positionX.end > positionX.start ? 30 : -30;
    return current + rotateBy;
  };

  const _dragMove = event => {
    positionX.end = event.clientX;
    if (!_hasDraggedEnough()) return;

    const current = _getCurrentRotation();
    const rotation = _getDraggedRotateTo(current);
    _setCssVars(config.durationDefault, rotation);
    positionX.start = event.clientX;
  };

  const _dragEnd = event => {
    dragLayer.removeEventListener('pointerup', _dragEnd);
    dragLayer.removeEventListener('pointermove', _dragMove);
  };

  const _dragStart = event => {
    event.preventDefault();
    positionX.start = event.clientX;
    dragLayer.addEventListener('pointerup', _dragEnd);
    dragLayer.addEventListener('pointermove', _dragMove);
  };
  
  const initEvents = (_ => {

    // Clicking on a chord moves it to the top of the wheel.
    clickLayer.addEventListener('click', _chordClicked);

    // Dragging the wheel incrementally rotates the wheel
    // Currently unsupported in Safari, though I'm certain they'll get around to it.
    if (window.PointerEvent) {
      dragLayer.addEventListener('pointerdown', _dragStart);
    }

    // Resets the rotation angle to be within +/- 360deg - Tidy like.
    rotationLayer.addEventListener('transitionend', _resetRotation);
    
  })();

}(window, document));






// Add a full-screen toggle button where supported.
// https://codepen.io/2kool2/pen/ZEzgQRx
// Version 1.5 06/10/2019
// Prefixes would be required for production.

function launchFullWindow(config) {

  "use strict";
  if (!supportsES6) return false;
  if (!document.fullscreenEnabled) return false;

  let cfg = {
    launchObjSelect : '[data-fullScreen]',
    launchBtnClass : 'fullScreen_btn',
    svgClass : 'fullScreen_svg',
    open : {
      icon : 'icon-fullScreen-open',
      extension : '-open',
      label : 'Launch into full screen',
      title : 'Full screen [f, f11]'
    },
    exit : {
      icon : 'icon-fullScreen-exit',
      extension : '-exit',
      label : 'Exit full screen',
      title : 'Exit full screen [f, f11, esc]'
    }
  };

  // Blend the parameter config into the default cfg
  Object.assign(cfg, config);
  
  const svgData = {
    open : {},
    exit : {}   
  }


  // Get symbol from the SVG definitions in the HTML
  const setSvgData = (_ => {

    const getData = param => {
      const symbol = document.getElementById(cfg[param].icon);
      if (!symbol) return false;
      svgData[param].class = cfg.svgClass;
      svgData[param].icon = cfg[param].icon;
      svgData[param].symbol = symbol.innerHTML;
      svgData[param].viewBox = symbol.getAttribute('viewBox');
    };

    getData('open');
    getData('exit');
  })();


  const _instantiateLaunchObj = launchObj => {

    const _getCfg = (param, toOpen) => cfg[toOpen ? 'open' : 'exit'][param];


    const _getSvgString = toOpen => {
      const param = _getCfg('extension', toOpen).substr(1);
      return `<svg className="${svgData[param].class}" aria-hidden="true" viewbox="${svgData[param].viewBox}">${svgData[param].symbol}</svg>`;
    }

    const _setBtnAttr = toOpen => {
      btn.className = cfg.launchBtnClass + _getCfg('extension', toOpen);
      btn.title = _getCfg('title', toOpen);
      btn.setAttribute('aria-label', _getCfg('label', toOpen));
      btn.innerHTML = _getSvgString(toOpen);
    }

    // Check to see if a button already exists in the html
    let btn = launchObj.querySelector('.' + cfg.launchBtnClass);
    if (!btn) {

      // If not, create one
      btn = document.createElement('button');
      launchObj.prepend(btn);
    }
    _setBtnAttr(true);

    const _toggleFullScreen = _ => {
      if (!document.fullscreenElement) {
        launchObj.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
      btn.focus();
    }

    const _onFullscreenChange = _ => _setBtnAttr(!document.fullscreenElement);

    {
      btn.addEventListener('click', _toggleFullScreen);

      // Toggle attr this way because esc key is not detected but change is!
      document.addEventListener('fullscreenchange', _onFullscreenChange);

      // Toggle if the f, or f11, key is pressed
      document.addEventListener('keydown', event => {
        if (event.keyCode === 70 || event.keyCode === 112) {
          _toggleFullScreen();
        }
      });
    }
    
  }

  // Multiple objects to launch
  const objs = document.querySelectorAll(cfg.launchObjSelect);
  for (const obj of objs) {
    _instantiateLaunchObj(obj);
  }

}

// Any object with data-fullScreen attribute by default
launchFullWindow();

export default App;
